import {
	Button,
	FormControl,
	FormHelperText,
	FormLabel,
	HStack,
	Input,
	Spinner,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react'
import React from 'react'
import { useForm } from 'react-hook-form'
import 'react-phone-input-2/lib/style.css'
import { useSendCognitoOtp } from '../../../hooks/login.hooks'
import { AUTH_LOGIN_MODE } from '../../../utils/constant'
import { LS_LOGIN_MODE } from '../login.util'
import { EMAIL_FORM_VIEW } from './email.config'

export default function EmailInput({ onSendOtpSuccess }) {
	const { handleSubmit, errors, register } = useForm()
	const { mutate, isLoading, isError } = useSendCognitoOtp()

	const onSave = data => {
		const { email } = data
		mutate(
			{ email },
			{
				onSuccess: async data => {
					sessionStorage.setItem(AUTH_LOGIN_MODE, LS_LOGIN_MODE.EMAIL)
					onSendOtpSuccess(data, EMAIL_FORM_VIEW.OTP_VIEW)
				},
				onError: err => {
					console.log('error', err)
				},
			}
		)
	}

	return (
		<form onSubmit={handleSubmit(onSave)}>
			<VStack
				my={4}
				rounded='lg'
				h='250px'
				w='300px'
				bg='white'
				boxShadow='md'
				px={4}
				py={5}
				spacing={8}
				justifyContent='center'
			>
				<FormControl id='email' pt={2} borderRadius='sm'>
					<FormLabel fontWeight='400' fontSize='md' mb={5}>
						Enter your register email
					</FormLabel>
					<Input
						placeholder='Enter your email'
						borderRadius='md'
						size='md'
						{...register('email', {
							pattern: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
						})}
					/>
					{errors && errors['email'] && (
						<FormHelperText color='red.300' fontWeight='400' fontSize='sm'>
							Please provide valid email
						</FormHelperText>
					)}
				</FormControl>
				<HStack alignItems='center'>
					<Button
						type='submit'
						bg='brand.800'
						color='white'
						size='md'
						minW='200px'
						variant='outline'
						fontWeight='400'
						isDisabled={!!isLoading}
					>
						Send OTP
					</Button>
				</HStack>
				{isError && (
					<Text color='red.300' fontWeight='400' fontSize='sm'>
						No user found!
					</Text>
				)}
				{isLoading ? (
					<Stack
						my={4}
						justifyContent='center'
						alignItems='center'
						direction='column'
					>
						<Spinner size='lg' />
						<Text color='brand.800'>Please, wait...</Text>
					</Stack>
				) : null}
			</VStack>
		</form>
	)
}
