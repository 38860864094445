/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from 'react'
import {
	Stack,
	Spinner,
	Text,
	SimpleGrid,
	Box,
	Button,
	Icon,
	Flex,
	Heading,
	Tooltip,
} from '@chakra-ui/react'
import { HiOutlineOfficeBuilding } from 'react-icons/hi'
import { useLoadOrganizationList } from '../../hooks/asset.hooks'
import { setLocalAuthDetails } from '../../utils/common.util'
import { useHistory } from 'react-router'
// import {
// 	sectionDetailedTrack,
// 	dataLayerTagManager,
// } from '../../containers/repository/repo.utils'
import { GA_CATEGORY_NAVBAR } from './../../containers/repository/repo.utils'
import { loginConst } from '../../utils/action.constant'

export default function OrganizationListView({ props }) {
	const { mutate, isLoading } = useLoadOrganizationList()
	const history = useHistory()

	const [orgnizationList, setOrgnizationList] = useState([])

	useEffect(() => {
		mutate(
			{},
			{
				onSuccess: async res => {
					const {
						data: { status, response },
					} = res
					if (status === 200) {
						setOrgnizationList(response)
						if (!!response && response.length === 1) {
							navigateTo(response[0])
						}
						// TODO: on Single Organization login directly.
					}
				},
			}
		)
	}, [])

	const navigateTo = org => {
		setLocalAuthDetails(org)
		localStorage.removeItem(loginConst.IS_AZURE_LOGIN)
		if (!!org.organization_inline_data) {
			let parseData = JSON.parse(org.organization_inline_data)

			localStorage.setItem(
				'color',
				!!parseData.color_1 ? parseData.color_1 : '#4daf89'
			)
			localStorage.setItem(
				'font',
				!!parseData.font_name ? parseData.font_name : 'Roboto'
			)
			localStorage.setItem(
				'imgWidth',
				!!parseData.logo_width_in_px
					? `${parseData.logo_width_in_px}px`
					: '60px'
			)
		} else {
			localStorage.setItem('color', '#4daf89')
			localStorage.setItem('font', 'Roboto')
			localStorage.setItem('imgWidth', '60px')
		}
		history.push('/files')
		window.location.reload()
	}

	if (isLoading) {
		return (
			<Stack
				my={3}
				justifyContent='center'
				alignItems='center'
				direction='column'
			>
				<Spinner size='lg' />
				<Text color='brand.800'>Please, wait...</Text>
			</Stack>
		)
	}

	return (
		<SimpleGrid
			spacing={5}
			rounded='lg'
			maxH='500px'
			w='400px'
			bg='white'
			boxShadow='md'
			p={4}
			overflowY='scroll'
			columns={1}
		>
			{(orgnizationList || []).map(org => (
				<Flex
					rounded='lg'
					maxH='500px'
					w='100%'
					boxShadow='md'
					alignItems='center'
					p={3}
					key={org.organization_id}
				>
					<Box
						rounded='md'
						size='sm'
						color='brand.800'
						bg='secondary'
						p={1}
						mr={2}
					>
						<Icon as={HiOutlineOfficeBuilding} h={6} w={6} />
					</Box>
					<Flex flex={1}>
						<Heading fontSize='md' fontWeight='500' textTransform='capitalize'>
							{org.organization_name}
						</Heading>
					</Flex>
					{Number(org.asset_type_category_id) === 45 ? (
						<Tooltip
							hasArrow
							label='Access denied. Please contact Admin'
							bg='red.600'
						>
							<Button
								color='brand.800'
								size='sm'
								variant='outline'
								fontWeight='400'
								onClick={() => {}}
							>
								View
							</Button>
						</Tooltip>
					) : (
						<Button
							color='brand.800'
							size='sm'
							variant='outline'
							fontWeight='400'
							onClick={() => {
								// sectionDetailedTrack({
								// 	category: GA_CATEGORY_NAVBAR,
								// 	action: 'Navigate Organization',
								// })
								// dataLayerTagManager('button_click', {
								// 	viewName: 'Organization List',
								// 	buttonName: 'View',
								// })
								navigateTo(org)
							}}
						>
							View
						</Button>
					)}
				</Flex>
			))}
		</SimpleGrid>
	)
}
