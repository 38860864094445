import { Heading, Link } from '@chakra-ui/react'
import { Amplify } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { LOGIN_MODE } from '../login.util'
import { EMAIL_FORM_VIEW } from './email.config'
import OrganizationListView from '../organization-list'
import EmailInput from './email.input'
import OTPNumberInput from '../otp-input/mail-otp'
import { AzureADLogin } from '../azure-login'

export function EmailOTPLogin({ setLoginMode }) {
	const [view, setView] = useState(EMAIL_FORM_VIEW.EMAIL_VIEW)
	const [user, setuser] = useState(null)

	useEffect(() => {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_EMAIL_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_EMAIL_IDENTITY_POOL_ID,
				userPoolWebClientId:
					process.env.REACT_APP_EMAIL_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_EMAIL_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})
	}, [])

	const onValidationSuccess = () => {
		setView(EMAIL_FORM_VIEW.ORG_VIEW)
	}

	if (EMAIL_FORM_VIEW.ORG_VIEW === view) {
		return (
			<>
				<Heading fontWeight='500' size='md' mb={3}>
					Select Organization To Access Grene Desk
				</Heading>
				<OrganizationListView></OrganizationListView>
			</>
		)
	}

	return (
		<>
			<Heading fontWeight='500' size='md'>
				Access Grene Desk via OTP
			</Heading>
			{view === EMAIL_FORM_VIEW.EMAIL_VIEW ? (
				<EmailInput
					onSendOtpSuccess={(user, view) => {
						setView(view)
						setuser(user)
					}}
				/>
			) : view === EMAIL_FORM_VIEW.OTP_VIEW ? (
				<OTPNumberInput
					isEmailView={true}
					user={user}
					onValidationSuccess={onValidationSuccess}
				/>
			) : null}
			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'QR Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via greneOS App',
					// })
					setLoginMode(LOGIN_MODE.QR)
				}}
			>
				Login via greneOS App
			</Link>
			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'Phone Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via phone',
					// })
					setLoginMode(LOGIN_MODE.OTP)
				}}
			>
				Login via phone
			</Link>
			{!!process.env.REACT_APP_AD_USER_POOL_ID ? (
				<Link
					fontSize='md'
					variant='string'
					fontWeight='500'
					color='blue.700'
					onClick={() => {
						setLoginMode(LOGIN_MODE.AZURE_AD)
					}}
				>
					Login via Outlook
				</Link>
			) : null}
		</>
	)
}
