import { Button, Heading, Link } from '@chakra-ui/react'
import { Amplify } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import 'react-phone-input-2/lib/style.css'
import { LOGIN_MODE } from '../login.util'
import { FORM_VIEW } from './otp.config'
import OTPNumberInput from '../otp-input'
import PhoneNumberInput from './phonenumber'
import OrganizationListView from '../organization-list'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_LOGIN,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { AzureADLogin } from '../azure-login'

export function OTPLogin({ setLoginMode }) {
	const [view, setView] = useState(FORM_VIEW.PHONE_VIEW)
	const [user, setuser] = useState(null)
	const [phoneDetail, setPhoneDetail] = useState(null)

	useEffect(() => {
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_OTP_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_OTP_IDENTITY_POOL_ID,
				userPoolWebClientId: process.env.REACT_APP_OTP_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_OTP_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})
	}, [])

	const onValidationSuccess = () => {
		setView(FORM_VIEW.ORG_VIEW)
	}

	if (FORM_VIEW.ORG_VIEW === view) {
		return (
			<>
				<Heading fontWeight='500' size='md' mb={3}>
					Select Organization To Access Grene Desk
				</Heading>
				<OrganizationListView></OrganizationListView>
			</>
		)
	}

	return (
		<>
			<Heading fontWeight='500' size='md'>
				Access Grene Desk via OTP
			</Heading>
			{view === FORM_VIEW.PHONE_VIEW ? (
				<PhoneNumberInput
					onSendOtpSuccess={(user, view, data) => {
						setView(view)
						setuser(user)
						setPhoneDetail(data)
					}}
				/>
			) : view === FORM_VIEW.OTP_VIEW ? (
				<>
					<OTPNumberInput
						user={user}
						phoneDetail={phoneDetail}
						onValidationSuccess={onValidationSuccess}
					/>
				</>
			) : null}
			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'QR Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via greneOS App',
					// })
					setLoginMode(LOGIN_MODE.QR)
				}}
			>
				Login via greneOS App
			</Link>
			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'EMAIL Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via Email',
					// })
					setLoginMode(LOGIN_MODE.EMAIL)
				}}
			>
				Login via Email
			</Link>
			{!!process.env.REACT_APP_AD_USER_POOL_ID ? (
				<Link
					fontSize='md'
					variant='string'
					fontWeight='500'
					color='blue.700'
					onClick={() => {
						setLoginMode(LOGIN_MODE.AZURE_AD)
					}}
				>
					Login via Outlook
				</Link>
			) : null}
		</>
	)
}
