import {
	Flex,
	HStack,
	PinInput,
	PinInputField,
	Spinner,
	Stack,
	Text,
	VStack,
	Button,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import 'react-phone-input-2/lib/style.css'
// import {
//   GA_CATEGORY_LOGIN,
//   sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { useAuthContext } from '../../../context/auth.context'
import { useSendCognitoOtp, useValidateOtp } from '../../../hooks/login.hooks'
import { loginConst } from '../../../utils/action.constant'
import { AuthMode } from '../../../utils/common.util'
// import { dataLayerTagManager } from './../../../containers/repository/repo.utils'
import {
	AUTH_LOGIN_MODE,
	AUTH_SESSION_CHECK,
	AUTH_SESSION_DATA,
} from '../../../utils/constant'
import AWS from 'aws-sdk'

export default function OTPNumberInput({
	isEmailView = false,
	user,
	onValidationSuccess,
	phoneDetail,
}) {
	const { dispatch } = useAuthContext()
	const { mutate, isLoading, isError } = useValidateOtp()

	const [otp, setOtp] = useState('')
	const [isResend, setIsResend] = useState(false)

	const {
		mutate: resendMutate,
		isLoading: isSending,
		isError: sendError,
	} = useSendCognitoOtp()

	const resendOtp = () => {
		setOtp('')
		let payload = {
			email: user.username,
		}
		if (!isEmailView && !!phoneDetail) {
			payload = {
				countryCode: phoneDetail.country, // user.username.slice(0, 3),
				mobile: phoneDetail.phone, /// user.username.slice(3),
			}
		}
		resendMutate(payload, {
			onSuccess: data => {
				console.log(['OTP SENT', data])
				setIsResend(data)
			},
		})
	}

	const onSetOTPAwsConfig = ({ idToken }) => {
		// Set the region where your identity pool exists (us-east-1, eu-west-1)
		AWS.config.region = process.env.REACT_APP_AWS_REGION

		// Configure the credentials provider to use your identity pool
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: process.env.REACT_APP_OTP_IDENTITY_POOL_ID,
			Logins: {
				[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_OTP_USER_POOL_ID}`]:
					idToken.jwtToken,
			},
		})
	}

	const validateOtp = otp => {
		mutate(
			{
				user: isResend || user,
				otp,
			},
			{
				onSuccess: data => {
					sessionStorage.setItem(AUTH_SESSION_DATA, JSON.stringify(data))
					sessionStorage.setItem(AUTH_SESSION_CHECK, true)
					dispatch({
						type: loginConst.LOGIN,
						payload: {
							// username,
							// token,
							...data,
							phoneDetail,
						},
					})
					onSetOTPAwsConfig(data)
					onValidationSuccess()
				},
			}
		)
	}

	return (
		<VStack
			my={4}
			rounded='lg'
			w='350px'
			bg='white'
			boxShadow='md'
			px={4}
			py={5}
			spacing={8}
			justifyContent='center'
		>
			<Flex justifyContent='center' alignItems='center' direction='column'>
				<Text fontWeight='400' fontSize='md' mb={4} textAlign='center'>
					Enter the verification code we just sent you on your{' '}
					{`${isEmailView ? 'Email' : 'Phone'}`}.
				</Text>
				<HStack w='100%' justifyContent='center'>
					<PinInput
						size='md'
						type='number'
						value={otp}
						onChange={value => {
							setOtp(value)
						}}
						onComplete={validateOtp}
						isDisabled={!!isLoading}
					>
						<PinInputField />
						<PinInputField />
						<PinInputField />
						<PinInputField />
						<PinInputField />
						<PinInputField />
					</PinInput>
				</HStack>
				{isError && (
					<Button
						my={4}
						bg='brand.800'
						color='white'
						size='md'
						minW='200px'
						variant='outline'
						fontWeight='400'
						onClick={() => {
							// sectionDetailedTrack({
							// 	category: GA_CATEGORY_LOGIN,
							// 	action: 'Resend OTP',
							// })
							// dataLayerTagManager('button_click', {
							// 	viewName: 'Login',
							// 	buttonName: 'Re-Send OTP',
							// })
							resendOtp()
						}}
						isLoading={!!isSending}
					>
						Re-Send OTP
					</Button>
				)}
			</Flex>
			{isError && (
				<Text color='red.300' fontWeight='400' fontSize='sm'>
					Invalid otp please provide the correct one.
				</Text>
			)}
			{!!isLoading ? (
				<Stack
					my={3}
					justifyContent='center'
					direction='column'
					alignItems='center'
				>
					<Spinner size='lg' color='brand.900' />
					<Text color='brand.900'>Please, wait...</Text>
				</Stack>
			) : null}
		</VStack>
	)
}
