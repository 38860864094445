/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Text, Flex, Heading, Link } from '@chakra-ui/react'
import AWS from 'aws-sdk'
import { QRCodeSVG } from 'qrcode.react'
import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { useCognitoSignIn } from '../../../hooks/login.hooks'
import { LOGIN_MODE, LS_LOGIN_MODE } from '../login.util'
import {
	AUTH_LOGIN_MODE,
	AUTH_PAYLOAD,
	AUTH_SESSION_CHECK,
	AUTH_SESSION_DATA,
} from '../../../utils/constant'
import { useAuthContext } from '../../../context/auth.context'
import { loginConst } from '../../../utils/action.constant'
import { useHistory } from 'react-router'
import { setLocalAuthDetails, decryptData } from '../../../utils/common.util'
import { Amplify, Auth } from 'aws-amplify'
import { useDeepStreamContext } from '../../../context/deepstream.context'
import { AzureADLogin } from '../azure-login'

export const QrCodeLogin = ({ setLoginMode }) => {
	const history = useHistory()
	const urlParams = useParams()
	const [loginChannel, setloginChannel] = useState(null)
	const [loginError, setLoginError] = useState(false)
	const { mutate } = useCognitoSignIn()
	const { dispatch } = useAuthContext()

	const {
		state: { ds },
	} = useDeepStreamContext()

	useEffect(() => {
		setloginChannel(Math.floor(100000 + Math.random() * 900000))
		Amplify.configure({
			Auth: {
				mandatorySignIn: true,
				userPoolId: process.env.REACT_APP_USER_POOL_ID,
				identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
				userPoolWebClientId: process.env.REACT_APP_WEB_NO_SECRETE_CLIENT_ID,
				region: process.env.REACT_APP_AWS_REGION,
			},
			Analytics: {
				disabled: true,
			},
		})

		// Auth.configure({
		//   mandatorySignIn: true,
		//   userPoolId: process.env.REACT_APP_USER_POOL_ID,
		//   identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
		//   userPoolWebClientId: process.env.REACT_APP_WEB_NO_SECRETE_CLIENT_ID,
		//   region: process.env.REACT_APP_AWS_REGION,
		// });

		return () => {
			setloginChannel('')
		}
	}, [])

	const onSetAwsConfig = ({ idToken }) => {
		// Set the region where your identity pool exists (us-east-1, eu-west-1)
		AWS.config.region = process.env.REACT_APP_AWS_REGION

		// Configure the credentials provider to use your identity pool
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
			Logins: {
				[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]:
					idToken.jwtToken,
			},
		})
	}

	const handleMessage = useCallback(data => {
		if (data && data.channel === loginChannel.toString()) {
			dispatch({ type: loginConst.LOGIN_IN_PROGRESS })
			const { message } = data
			try {
				if (!!message.trim().split('-')[1]) {
					const msg = message.trim().split('-')
					if (msg[1] === loginChannel.toString()) {
						setLogin(msg[0])
					}
				} else if (!!message) {
					const msg = message
					if (msg) {
						setLogin(msg)
						ds.event.emit(`${loginChannel.toString()}-response`, 'success')
					}
				} else {
					alert('Login Error')
				}
			} catch (e) {
				alert('Login Error')
			}
		}
	})

	useEffect(() => {
		if (!!loginChannel && Object.keys(urlParams).length > 0) {
			ds.event.subscribe(loginChannel.toString(), data => {
				console.log({ data })
			})
			const authData = decryptData(urlParams.params)
			setLogin(authData)
		}
	}, [loginChannel, urlParams])

	useEffect(() => {
		if (loginChannel && ds) {
			setLoginError(false)
			ds.on('error', function (error, event, topic) {
				console.log(error, event, topic)
			})
			ds.event.subscribe(loginChannel.toString(), data => {
				if (!!data) {
					handleMessage({ channel: loginChannel.toString(), message: data })
				}
			})
		}

		return () => {
			if (loginChannel) {
				if (ds) {
					ds.event.unsubscribe(loginChannel.toString(), data => {
						console.log(data)
					})
				}
			}
		}
	}, [loginChannel])

	const setLogin = authData => {
		localStorage.setItem(AUTH_PAYLOAD, authData)
		const auth = JSON.parse(atob(authData))
		mutate(auth, {
			onSuccess: data => {
				sessionStorage.setItem(AUTH_SESSION_CHECK, true)
				sessionStorage.setItem(AUTH_LOGIN_MODE, LS_LOGIN_MODE.QR)
				sessionStorage.setItem(AUTH_SESSION_DATA, JSON.stringify(data))
				dispatch({
					type: loginConst.LOGIN,
					payload: {
						...auth,
						...data,
					},
				})
				setLocalAuthDetails({
					account_id: auth.accountId,
					asset_id: auth.assetId,
					asset_token_auth: auth.encToken,
					asset_encryption_token_id: auth.encToken,
					organization_id: auth.organizationId,
					workforce_id: auth.workforceId,
				})
				onSetAwsConfig(data)
				history.push('/files')
			},
			onError: error => {
				console.log('error', error)
				setLoginError(true)
				dispatch({
					type: loginConst.LOGIN_IN_FAILED,
					payload: {
						error,
					},
				})
			},
		})
	}

	return (
		<>
			<Flex
				flex={1}
				h='full'
				alignItems='center'
				justifyContent='center'
				flexDir='column'
			>
				<Heading fontWeight='500' size='md'>
					Login via greneOS App
				</Heading>
				{!!loginError ? (
					<Text fontWeight='400' size='sm' color='red.600' mt={5}>
						Login failed please try scanning again!
					</Text>
				) : null}
				<Box
					my={10}
					rounded='lg'
					h='250px'
					w='250px'
					bg='white'
					boxShadow='md'
					padding={8}
				>
					<QRCodeSVG
						renderAs={'canvas'}
						style={{
							height: '100%',
							width: '100%',
						}}
						level='Q'
						value={
							process.env.REACT_APP_QR_PREFIX === 'production'
								? `Desker-${loginChannel}`
								: `Desker-${loginChannel}-${process.env.REACT_APP_QR_PREFIX}`
						}
					/>
				</Box>
				<Link
					fontSize='md'
					variant='string'
					fontWeight='500'
					color='blue.700'
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_LOGIN,
						// 	action: 'Switch Login Mode',
						// 	label: 'EMAIL Login',
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Login',
						// 	buttonName: 'Login via Email',
						// })
						setLoginMode(LOGIN_MODE.EMAIL)
					}}
				>
					Login via Email
				</Link>
				<Link
					fontSize='md'
					variant='string'
					fontWeight='500'
					color='blue.700'
					onClick={() => {
						// sectionDetailedTrack({
						// 	category: GA_CATEGORY_LOGIN,
						// 	action: 'Switch Login Mode',
						// 	label: 'Phone Login',
						// })
						// dataLayerTagManager('button_click', {
						// 	viewName: 'Login',
						// 	buttonName: 'Login via Phone',
						// })
						setLoginMode(LOGIN_MODE.OTP)
					}}
				>
					Login via Phone
				</Link>
				{!!process.env.REACT_APP_AD_USER_POOL_ID ? (
					<Link
						fontSize='md'
						variant='string'
						fontWeight='500'
						color='blue.700'
						onClick={() => {
							setLoginMode(LOGIN_MODE.AZURE_AD)
						}}
					>
						Login via Outlook
					</Link>
				) : null}

				<Text mt={4}>If you are experiencing login issues,</Text>
				<Text>
					please contact us at <b>support@grenerobotics.com</b> for assistance.
				</Text>
			</Flex>
		</>
	)
}
