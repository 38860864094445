import {
	Heading,
	Link,
	Button,
	HStack,
	Box,
	VStack,
	Text,
	Stack,
	Spinner,
} from '@chakra-ui/react'
import { Amplify, Auth, Hub } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { LOGIN_MODE } from '../login.util'
// import {
// 	dataLayerTagManager,
// 	GA_CATEGORY_LOGIN,
// 	sectionDetailedTrack,
// } from '../../../containers/repository/repo.utils'
import { useLocation } from 'react-router-dom'
import AWS from 'aws-sdk'
import { useLoadOrganizationList } from '../../../hooks/asset.hooks'
import {
	AUTH_E_CODE,
	AUTH_SESSION_CHECK,
	AUTH_SESSION_DATA,
} from '../../../utils/constant'
import { EMAIL_FORM_VIEW } from '../email-login/email.config'
import OrganizationListView from '../organization-list'
import { loginConst } from '../../../utils/action.constant'
import { useAuthContext } from '../../../context/auth.context'
import { isLocalhost } from '../../../utils/common.util'

export const AzureADLogin = ({ setLoginMode }) => {
	const [isError, setIsError] = useState(null)
	const [view, setView] = useState(EMAIL_FORM_VIEW.EMAIL_VIEW)
	const { mutate, isLoading } = useLoadOrganizationList()

	const { dispatch } = useAuthContext()

	const awsConfig = {
		Auth: {
			identityPoolId: `${process.env.REACT_APP_AD_IDENTITY_POOL_ID}`,
			region: `${process.env.REACT_APP_AWS_REGION}`,
			userPoolId: `${process.env.REACT_APP_AD_USER_POOL_ID}`,
			userPoolWebClientId: `${process.env.REACT_APP_AZURE_AD_WEB_NO_SECRETE_CLIENT_ID}`,
			oauth: {
				domain: `${process.env.REACT_APP_AD_DOMAIN_NAME}`,
				scope: ['email', 'openid'],
				redirectSignIn: isLocalhost()
					? 'http://localhost:3000/login'
					: process.env.REACT_APP_REDIRECT_SIGNIN_URL,
				redirectSignOut: isLocalhost()
					? 'http://localhost:3000/login'
					: process.env.REACT_APP_REDIRECT_SIGNOUT_URL,
				responseType: `${process.env.REACT_APP_AD_RESPONSE_TYPE}`,
				label: 'Log in with your company SSO',
				clientId: `${process.env.REACT_APP_AZURE_AD_WEB_NO_SECRETE_CLIENT_ID}`,
			},
		},
		Analytics: {
			disabled: true,
		},
	}
	Amplify.configure({ ...awsConfig, ssr: true })

	const onSetOTPAwsConfig = async ({ idToken }) => {
		// Set the region where your identity pool exists (us-east-1, eu-west-1)
		AWS.config.region = process.env.REACT_APP_AWS_REGION
		// Configure the credentials provider to use your identity pool
		AWS.config.credentials = new AWS.CognitoIdentityCredentials({
			IdentityPoolId: process.env.REACT_APP_AD_IDENTITY_POOL_ID,
			Logins: {
				[`cognito-idp.ap-south-1.amazonaws.com/${process.env.REACT_APP_AD_USER_POOL_ID}`]:
					idToken.jwtToken,
			},
		})
	}

	const getUser = async () => {
		const { signInUserSession } = await Auth.currentAuthenticatedUser()
		console.log({ signInUserSession })
		if (signInUserSession?.idToken?.payload?.email) {
			sessionStorage.setItem(AUTH_SESSION_CHECK, true)
			sessionStorage.setItem(
				AUTH_SESSION_DATA,
				JSON.stringify(signInUserSession)
			)
			dispatch({
				type: loginConst.LOGIN,
				payload: signInUserSession,
			})
			setIsError(null)
			onValidationSuccess()
		} else {
			setIsError('Login Error')
		}
	}

	useEffect(() => {
		Hub.listen('auth', async ({ payload: { event, data } }) => {
			switch (event) {
				case 'signIn':
				case 'cognitoHostedUI':
					await onSetOTPAwsConfig(data.signInUserSession)
					await getUser()
					break
				case 'signOut':
					await Auth.signOut()
					break
				case 'signIn_failure':
				case 'cognitoHostedUI_failure':
					console.log('Sign in failure', data)
					break
				default:
					break
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onValidationSuccess = () => {
		setView(EMAIL_FORM_VIEW.ORG_VIEW)
	}

	if (EMAIL_FORM_VIEW.ORG_VIEW === view) {
		return (
			<>
				<Heading fontWeight='500' size='md' mb={3}>
					Select Organization To Access Grene Desk
				</Heading>
				<OrganizationListView></OrganizationListView>
			</>
		)
	}

	return (
		<>
			<Heading fontWeight='500' size='md'>
				Access Grene Desk via Outlook
			</Heading>
			<VStack
				my={4}
				rounded='lg'
				w='300px'
				bg='white'
				boxShadow='md'
				px={4}
				py={5}
				spacing={8}
				justifyContent='center'
			>
				<HStack alignItems='center'>
					<Button
						type='submit'
						bg='brand.800'
						color='white'
						size='md'
						minW='200px'
						variant='outline'
						fontWeight='400'
						isDisabled={!!isLoading}
						onClick={() => {
							setLoginMode(LOGIN_MODE.AZURE_AD)
							localStorage.setItem(loginConst.IS_AZURE_LOGIN, true)
							Auth.federatedSignIn({ provider: 'AzureAD' })
						}}
					>
						Outlook Login
					</Button>
				</HStack>
				{isError && (
					<Text color='red.300' fontWeight='400' fontSize='sm'>
						No user found!
					</Text>
				)}
				{isLoading ? (
					<Stack
						my={4}
						justifyContent='center'
						alignItems='center'
						direction='column'
					>
						<Spinner size='lg' />
						<Text color='brand.800'>Please, wait...</Text>
					</Stack>
				) : null}
			</VStack>

			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'QR Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via greneOS App',
					// })
					setLoginMode(LOGIN_MODE.QR)
				}}
			>
				Login via greneOS App
			</Link>
			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'EMAIL Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via Email',
					// })
					setLoginMode(LOGIN_MODE.EMAIL)
				}}
			>
				Login via Email
			</Link>
			<Link
				fontSize='md'
				variant='string'
				fontWeight='500'
				color='blue.700'
				onClick={() => {
					// sectionDetailedTrack({
					// 	category: GA_CATEGORY_LOGIN,
					// 	action: 'Switch Login Mode',
					// 	label: 'Phone Login',
					// })
					// dataLayerTagManager('button_click', {
					// 	viewName: 'Login',
					// 	buttonName: 'Login via phone',
					// })
					setLoginMode(LOGIN_MODE.OTP)
				}}
			>
				Login via phone
			</Link>
		</>
	)
}
